import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from './Message'
import Loading from './Loading'
import {
	listCoupons,
	deleteCoupon,
	createCoupon,
} from '../actions/couponActions'
import { COUPON_CREATE_RESET } from '../constants/couponConstants'

const CouponList = ({ history }) => {
	const dispatch = useDispatch()

	const [deletedCoupon, setDeletedCoupon] = useState()

	const couponList = useSelector((state) => state.couponList)
	const { loading, error, coupons } = couponList

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const couponDelete = useSelector((state) => state.couponDelete)
	const { success: successDelete } = couponDelete

	const couponCreate = useSelector((state) => state.couponCreate)
	const {
		loading: loadingCreate,
		error: errorCreate,
		success: successCreate,
		coupon: createdCoupon,
	} = couponCreate

	useEffect(() => {
		dispatch({ type: COUPON_CREATE_RESET })
		if (!userInfo.isAdmin) {
			history.push('/login')
		}

		if (successCreate) {
			history.push(`/admin/coupon/${createdCoupon._id}/edit`)
		} else {
			dispatch(listCoupons())
		}
	}, [dispatch, history, userInfo, successDelete, successCreate, createdCoupon])

	const deleteCouponHandler = (id, code) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteCoupon(id))
			setDeletedCoupon(code)
		}
	}

	const createCouponHandler = () => {
		//Create Product
		dispatch(createCoupon())
	}

	return (
		<Container style={styles.container}>
			<Row className="align-items-center">
				<Col className="text-right">
					<Button className="my-3" onClick={createCouponHandler}>
						Create Coupon
					</Button>
				</Col>
			</Row>
			<h1 className="text-center pb-2">All Coupons</h1>
			{successDelete && (
				<Message variant="success">{`Coupon ${deletedCoupon} Deleted!`}</Message>
			)}
			{loadingCreate && <Loading />}
			{errorCreate && <Message variant="danger">{errorCreate}</Message>}
			{loading ? (
				<Loading />
			) : error ? (
				<Message variant="danger">{error}</Message>
			) : (
				<Table striped bordered hover responsive className="table-sm">
					<thead>
						<tr>
							<th>ID</th>
							<th>Code</th>
							<th>Amount?</th>
							<th>One Time?</th>
							<th>Free Ship?</th>
							<th>Tax Free?</th>
							<th>Expires On?</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{coupons.map((coupon) => (
							<tr key={coupon._id}>
								<td>{coupon._id}</td>
								<td>{coupon.code}</td>
								<td>${coupon.amount.toFixed(2)}</td>
								<td>{coupon.oneTime ? 'True' : 'False'}</td>
								<td>{coupon.freeShip ? 'True' : 'False'}</td>
								<td>{coupon.taxFree ? 'True' : 'False'}</td>
								<td>{coupon.expires ? coupon.expires : 'Not Set'}</td>
								<td align="center">
									<LinkContainer to={`/admin/coupon/${coupon._id}/edit`}>
										<Button className="mr-2">Edit</Button>
									</LinkContainer>

									<Button
										variant="danger"
										onClick={() => {
											deleteCouponHandler(coupon._id, coupon.code)
										}}
									>
										Delete
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			)}
		</Container>
	)
}

const styles = {
	container: {
		marginTop: '5rem',
		marginBottom: '15rem',
		paddingTop: '5rem',
		paddingBottom: '5rem',
		backgroundColor: 'white',
	},
}

export default CouponList
