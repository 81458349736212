import React from 'react'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Paginate = ({ pages, page, isAdmin=false, keyword='', category='' }) => {


    return pages > 1 && (
        <Pagination className='justify-content-center'>

            
            {page !== 1 && (
                <>
                <LinkContainer to={!isAdmin ? keyword ? `/search/${keyword}/page/1` : category ? `/category/${category}/page/1` : `/shop/page/1` : `/admin/productlist/1`}>
                        <Pagination.First active={false} />
                </LinkContainer>

                <LinkContainer key={page - 1} to={!isAdmin ? keyword ? `/search/${keyword}/page/${page - 1}` : category ? `/category/${category}/page/${page - 1}` : `/shop/page/${page - 1}` : `/admin/productlist/${page - 1}`}>
                        <Pagination.Prev active={page - 1 === page} />
                </LinkContainer>
                </>
            )}

            
            {pages > 10 && page <= 6 ? (
                [...Array(pages).keys()].slice([page], [page + 7]).map(x => (
                    <LinkContainer key={x} to={!isAdmin ? keyword ? `/search/${keyword}/page/${x}` : category ? `/category/${category}/page/${x}` : `/shop/page/${x}` : `/admin/productlist/${x}`}>
                        <Pagination.Item active={x === page}>{x}</Pagination.Item>
                    </LinkContainer>
                ))
                  
            ) : pages > 10 && page > 6 ? (
                [...Array(pages).keys()].slice([page - 4], [page + 3]).map(x => (
                    <LinkContainer key={x + 1} to={!isAdmin ? keyword ? `/search/${keyword}/page/${x + 1}` : category ? `/category/${category}/page/${x + 1}` : `/shop/page/${x + 1}` : `/admin/productlist/${x + 1}`}>
                        <Pagination.Item active={x + 1 === page}>{x + 1}</Pagination.Item>
                    </LinkContainer>
                ))
            ) : (
                [...Array(pages).keys()].map(x => (
                    <LinkContainer key={x + 1} to={!isAdmin ? keyword ? `/search/${keyword}/page/${x + 1}` : category ? `/category/${category}/page/${x + 1}` : `/shop/page/${x + 1}` : `/admin/productlist/${x + 1}`}>
                        <Pagination.Item active={x + 1 === page}>{x +1}</Pagination.Item>
                    </LinkContainer>
                ))
            )}

            
            {page !== [...Array(pages)].length && (
                <>
                <LinkContainer key={page + 1} to={!isAdmin ? keyword ? `/search/${keyword}/page/${page + 1}` : category ? `/category/${category}/page/${page + 1}` : `/shop/page/${page + 1}` : `/admin/productlist/${page + 1}`}>
                        <Pagination.Next active={page + 1 === page} />
                </LinkContainer>

                <LinkContainer to={!isAdmin ? keyword ? `/search/${keyword}/page/${[...Array(pages)].length}` : category ? `/category/${category}/page/${[...Array(pages)].length}` : `/shop/page/${[...Array(pages)].length}` : `/admin/productlist/${[...Array(pages)].length}`}>
                        <Pagination.Last />
                </LinkContainer>
                </>
            )}
            
            
        </Pagination>
    )
}

export default Paginate
