import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Container, Form, Button, Row, Col, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loading from '../components/Loading'
import FormContainer from '../components/FormContainer'
import { listProductDetails, updateProduct } from '../actions/productActions'
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants'

const EditProduct = ({ match, history }) => {
	const productID = match.params.id

	const [sku, setSku] = useState('')
	const [name, setName] = useState('')
	const [price, setPrice] = useState(0)
	const [qty, setQty] = useState(0)
	const [description, setDescription] = useState('')
	const [category, setCategory] = useState('')
	const [images, setImages] = useState([])
	const [color, setColor] = useState('')
	const [slug, setSlug] = useState('')
	const [featured, setFeatured] = useState(false)
	const [uploading, setUploading] = useState(false)

	const categoriesList = [
		'zi-collection',
		'cups',
		'pens',
		'wine stoppers',
		'keychains',
		'cup charms',
		'accessories',
	]

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const productDetails = useSelector((state) => state.productDetails)
	const { loading, error, product } = productDetails

	const productUpdate = useSelector((state) => state.productUpdate)
	const {
		loading: loadingUpdate,
		error: errorUpdate,
		success: successUpdate,
	} = productUpdate

	useEffect(() => {
		if (successUpdate) {
			dispatch({ type: PRODUCT_UPDATE_RESET })
			dispatch(listProductDetails(productID))
			history.goBack()
		} else {
			if (!product.name || product._id !== productID) {
				dispatch(listProductDetails(productID))
			} else {
				setSku(product.sku)
				setName(product.name)
				setPrice(product.price)
				setQty(product.qty)
				setDescription(product.description)
				setCategory(product.category)
				setColor(product.color || '')
				setSlug(product.slug)
				setFeatured(product.featured)
				setImages(product.images)
			}
		}
	}, [dispatch, history, productID, product, successUpdate])

	const updateHandler = (e) => {
		e.preventDefault()
		//Update Product Dispatch
		dispatch(
			updateProduct({
				_id: productID,
				sku,
				name,
				price,
				qty,
				description,
				category,
				images,
				color,
				slug,
				featured,
			})
		)
	}

	const handleFileChange = async (e) => {
		setUploading(true)
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		var toUpload = [...e.target.files] // Update images with selected files
		const formData = new FormData()
		Array.from(toUpload).forEach((file, index) => {
			formData.append(`Image`, file) // Use a unique key for each file
		})
		try {
			const upload = await axios.post(
				'/api/products/uploadImage',
				formData,
				config
			)
			setImages((prevImages) => [...prevImages, ...upload.data])
		} catch (error) {
			console.error('Error uploading files:', error)
		} finally {
			setUploading(false)
		}
	}

	const handleDeleteImage = async (e, key) => {
		setUploading(true)
		// Create a copy of the images array
		const updatedImages = [...images]

		// Remove the item at the specified index
		updatedImages.splice(key, 1)

		// Update the state with the new array
		setImages(updatedImages)
		setUploading(false)
	}

	return (
		<Container style={styles.padding}>
			<Button
				href="#"
				onClick={() => history.goBack()}
				className="btn btn-light my-3"
			>
				Go Back
			</Button>
			<FormContainer>
				<h1>Edit Product</h1>
				{loadingUpdate && <Loading />}
				{errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
				{loading || uploading ? (
					<Loading />
				) : error ? (
					<Message variant="danger">{error}</Message>
				) : (
					<Form onSubmit={updateHandler}>
						<Form.Group controlId="sku">
							<Form.Label>SKU</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter sku"
								value={sku}
								onChange={(e) => setSku(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId="name">
							<Form.Label>Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId="price">
							<Form.Label>Price</Form.Label>
							<Form.Control
								type="number"
								placeholder="Enter price"
								value={price}
								onChange={(e) => setPrice(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId="qty">
							<Form.Label>Quantity</Form.Label>
							<Form.Control
								type="number"
								placeholder="Enter qty"
								value={qty}
								onChange={(e) => setQty(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId="description">
							<Form.Label>Description</Form.Label>
							<Form.Control
								as="textarea"
								rows={4}
								placeholder="Enter description"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							></Form.Control>
						</Form.Group>

                        <Form.Group controlId="category">
                            <Form.Label>Category</Form.Label>
                            <Form.Control
                                as="select"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <option value="">Select category</option>
                                {categoriesList.map((cat) => (
                                    <option key={cat} value={cat}>
                                        {cat}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

						<Form.Group controlId="images">
							<Form.Label>Product Images</Form.Label>
							<Form.Control type="file" multiple onChange={handleFileChange} />
							<Form.Text className="text-muted">
								Select multiple images
							</Form.Text>
							{images.length > 0 && (
								<Row className="mt-3">
									{images.map((img, key) => (
										<Col md={2} key={key}>
											<Image src={img.Location} alt="product" fluid thumbnail />
											<Button
												variant="danger"
												className="mt-2"
												onClick={() => handleDeleteImage(key)}
											>
												Delete
											</Button>
										</Col>
									))}
								</Row>
							)}
						</Form.Group>

						<Form.Group controlId="color">
							<Form.Label>Color</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter color"
								value={color}
								onChange={(e) => setColor(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId="slug">
							<Form.Label>Slug</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter slug"
								value={slug}
								onChange={(e) => setSlug(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId="isfeatured">
							<Form.Check
								type="checkbox"
								label="Featured?"
								checked={featured}
								onChange={(e) => setFeatured(e.target.checked)}
							></Form.Check>
						</Form.Group>

						<Button type="submit" variant="primary">
							Update Product
						</Button>
					</Form>
				)}
			</FormContainer>
		</Container>
	)
}

const styles = {
	padding: {
		paddingTop: '5rem',
		paddingBottom: '15rem',
	},
}

export default EditProduct
