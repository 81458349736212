import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, Table, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from './Message'
import Loading from './Loading'
import { listOrders, deleteOrder, shipOrder } from '../actions/orderActions'

const OrderList = ({ match, history }) => {
	const dispatch = useDispatch()

	const [isPaid, setIsPaid] = useState(true)

	const orderList = useSelector((state) => state.orderList)
	const { loading, error, orders } = orderList

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const orderDelete = useSelector((state) => state.orderDelete)
	const {
		loading: loadingDelete,
		error: errorDelete,
		success: successDelete,
	} = orderDelete

	const orderShip = useSelector((state) => state.orderShip)
	const {
		loading: loadingShip,
		error: errorShip,
		success: successShip,
	} = orderShip

	useEffect(() => {
		if (userInfo && userInfo.isAdmin) {
			dispatch(listOrders())
		} else {
			history.push('/login')
		}

		console.log(isPaid)
	}, [
		dispatch,
		history,
		userInfo,
		successDelete,
		successShip,
		setIsPaid,
		isPaid,
	])

	const deleteOrderHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			//Delete Product
			dispatch(deleteOrder(id))
		}
	}

	const shipOrderHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			//Ship Product
			dispatch(shipOrder(id))
		}
	}

	const setPaidState = (e) => {
		if (e === 'true') {
			setIsPaid(true)
		} else {
			setIsPaid(false)
		}
	}

	return (
		<Container style={styles.container}>
			<h1 className="text-center pb-2">All Orders</h1>
			<Form>
				<Form.Group controlId="orderStatus">
					<Form.Label>Filter By:</Form.Label>
					<Form.Control
						as="select"
						value={isPaid}
						onChange={(e) => setPaidState(e.target.value)}
					>
						<option value={true}>Paid</option>
						<option value={false}>Not Paid</option>
					</Form.Control>
				</Form.Group>
			</Form>
			{loadingDelete && <Loading />}
			{errorDelete && <Message variant="danger">{errorDelete}</Message>}
			{loadingShip && <Loading />}
			{errorShip && <Message variant="danger">{errorShip}</Message>}
			{loading ? (
				<Loading />
			) : error ? (
				<Message variant="danger">{error}</Message>
			) : (
				<Table striped bordered hover responsive className="table-sm">
					<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th>Email</th>
							<th>Total</th>
							<th>Paid?</th>
							<th>Delivered?</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{orders
							.filter((order) => order.isPaid === isPaid)
							.map((order) => (
								<tr key={order._id}>
									<td>{order._id}</td>
									<td>{order.user.name}</td>
									<td>{order.user.email}</td>
									<td>${order.totalPrice.toFixed(2)}</td>
									<td>{order.isPaid ? 'Paid' : 'Not Paid'}</td>
									<td>{order.isDelivered ? 'Delivered' : 'Not Delivered'}</td>
									<td align="center">
										<LinkContainer to={`/order/${order._id}`}>
											<Button className="mr-2">Go To Order</Button>
										</LinkContainer>

										{order.isDelivered && (
											<Button
												className="mr-2"
												variant="disabled"
												onClick={() => {
													shipOrderHandler(order._id)
												}}
											>
												Mark Shipped
											</Button>
										)}
										{!order.isDelivered && (
											<Button
												className="mr-2"
												variant="success"
												onClick={() => {
													shipOrderHandler(order._id)
												}}
											>
												Mark Shipped
											</Button>
										)}

										{order.isPaid && (
											<Button
												variant="disabled"
												onClick={() => {
													deleteOrderHandler(order._id)
												}}
											>
												Delete
											</Button>
										)}
										{!order.isPaid && (
											<Button
												variant="danger"
												onClick={() => {
													deleteOrderHandler(order._id)
												}}
											>
												Delete
											</Button>
										)}
									</td>
								</tr>
							))}
					</tbody>
				</Table>
			)}
		</Container>
	)
}

const styles = {
	container: {
		marginTop: '5rem',
		marginBottom: '15rem',
		paddingTop: '5rem',
		paddingBottom: '5rem',
		backgroundColor: 'white',
	},
}

export default OrderList
