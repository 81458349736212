import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

//Bring in components
import Headshot from '../images/headshot.jpg'

const About = () => {
    return (
        <>
            <Container>
                <Row className='justify-content-center py-5'>
                    
                        <img style={styles.image} src={Headshot} alt='Paige' />
                    
                </Row>
                <Row>
                    <Col className='text-center'>
                        <h2>Why Paparazzi?</h2> 
                        <p>I started out as a customer of other Paparazzi consultants, and never thought I would get into selling products through another company, but Paparazzi is truly different then other companies. Their product is something I can truly get behind, and fully enjoy so I decided to give it a shot.  Over a year later and I am still here with more bling than ever!</p>
                    </Col>
                    <Col className='text-center' style={styles.bold}>
                        <p>IF I CAN SELL YOU JEWELRY FOR $5 AND MAKE YOU FEEL BEAUTIFUL THEN I HAVE SUCCEEDED!</p>
                    </Col>
                    <Col className='text-center'>
                        <h2>Pricing & Shipping</h2>
                        <p>All our adult pieces, and hair bows are just $5</p>
                        <br />
                        <p>All Kids pieces are just $1</p>
                        <br />
                        <p>I charge a flat shipping rate of $3.50 for a single item, and $5 after that anywhere in the USA!</p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

const styles = {
    image: {
        borderRadius: '50%',
    },
    bold: {
        fontWeight: 'bold',
    },
}

export default About
