import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loading from '../components/Loading'
import FormContainer from '../components/FormContainer'
import { getUserDetails, updateUser } from '../actions/userActions'
import { USER_UPDATE_RESET } from '../constants/userConstants'

const EditUser = ({ match, history }) => {
    const userID = match.params.id

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [isAdmin, setIsAdmin] = useState(false)
    const [isAPI, setIsAPI] = useState(false)


    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.userDetails)
    const { loading, error, user } = userDetails
    
    const userUpdate = useSelector(state => state.userUpdate)
    const { loading:loadingUpdate, success:successUpdate, error:errorUpdate} = userUpdate

    useEffect(() => {
        if (successUpdate) {
          dispatch({ type: USER_UPDATE_RESET })
          history.push('/admin/userlist')
        } else {
          if (!user.name || user._id !== userID) {
            dispatch(getUserDetails(userID))
          } else {
            setName(user.name)
            setEmail(user.email)
            setIsAdmin(user.isAdmin)
            setIsAPI(user.isAPI)
          }
        }
      }, [dispatch, history, userID, user, successUpdate])

    const updateHandler = (e) => {
        e.preventDefault()
        dispatch(updateUser({ _id: userID, name, email, isAdmin, isAPI }))
    }

    return (
        <Container style={styles.padding}>
          <Link to='/admin/userlist' className='btn btn-light my-3'>
            Go Back
          </Link>
          <FormContainer>
            <h1>Edit User</h1>
            {loadingUpdate && <Loading />}
            {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
            {loading ? (
              <Loading />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <Form onSubmit={updateHandler}>
                <Form.Group controlId='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type='name'
                    placeholder='Enter name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
    
                <Form.Group controlId='email'>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Enter email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>
    
                <Form.Group controlId='isadmin'>
                  <Form.Check
                    type='checkbox'
                    label='Is Admin'
                    checked={isAdmin}
                    onChange={(e) => setIsAdmin(e.target.checked)}
                  ></Form.Check>
                </Form.Group>

                <Form.Group controlId='isapi'>
                  <Form.Check
                    type='checkbox'
                    label='Is API'
                    checked={isAPI}
                    onChange={(e) => setIsAPI(e.target.checked)}
                  ></Form.Check>
                </Form.Group>
    
                <Button type='submit' variant='primary'>
                  Update
                </Button>
              </Form>
            )}
          </FormContainer>
        </Container>
      )
}

const styles = {
    padding: {
        paddingTop: '5rem',
        paddingBottom: '15rem',
    },
}

export default EditUser
