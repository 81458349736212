import React from 'react'

import { ListGroup, Row, Col } from 'react-bootstrap'

const CalculateCart = ({ cart, coupon, userToken }) => {
	if (coupon && coupon.freeShip) {
		cart.shippingPrice = 0.0
		cart.totalPrice = Number(cart.itemsPrice) + Number(cart.taxPrice)
	}

	if (coupon && coupon.taxFree) {
		cart.taxPrice = 0.0
		cart.totalPrice = Number(cart.itemsPrice) + Number(cart.shippingPrice)
	}

	if (coupon && coupon.freeShip && coupon.taxFree) {
		cart.taxPrice = 0.0
		cart.shippingPrice = 0.0
		cart.totalPrice = Number(cart.itemsPrice)
	}

	if (coupon && coupon.amount) {
		cart.totalPrice = Number(cart.totalPrice) - Number(coupon.amount)
		cart.totalPrice = Number(cart.totalPrice).toFixed(2)
	}

	return (
		<>
			<ListGroup.Item style={{ backgroundColor: 'white', color: 'black' }}>
				<h2>Order Summary</h2>
			</ListGroup.Item>
			<ListGroup.Item style={{ backgroundColor: 'white', color: 'black' }}>
				<Row>
					<Col>Items</Col>
					<Col>${cart.itemsPrice}</Col>
				</Row>
			</ListGroup.Item>
			{!cart.shippingPrice ? (
				''
			) : (
				<ListGroup.Item style={{ backgroundColor: 'white', color: 'black' }}>
					<Row>
						<Col>Shipping</Col>
						<Col>${cart.shippingPrice}</Col>
					</Row>
				</ListGroup.Item>
			)}
			<ListGroup.Item style={{ backgroundColor: 'white', color: 'black' }}>
				<Row>
					<Col>Tax</Col>
					<Col>${cart.taxPrice}</Col>
				</Row>
			</ListGroup.Item>

			{coupon && coupon.amount !== 0 && (
				<ListGroup.Item style={{ backgroundColor: 'white', color: 'black' }}>
					<Row>
						<Col>Discount</Col>
						<Col>${coupon.amount.toFixed(2)}</Col>
					</Row>
				</ListGroup.Item>
			)}

			<ListGroup.Item style={{ backgroundColor: 'white', color: 'black' }}>
				<Row>
					<Col>Total</Col>
					<Col>${cart.totalPrice}</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item
				style={{ backgroundColor: 'white', color: 'black' }}
			></ListGroup.Item>
		</>
	)
}

export default CalculateCart
