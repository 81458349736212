import React, { useState } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from './FormContainer'
import { savePaymentMethod } from '../actions/cartActions'
import CheckoutSteps from '../components/CheckoutSteps'

const Payment = ({ history }) => {
	const cart = useSelector((state) => state.cart)
	const { shippingAddress } = cart

	if (!shippingAddress) {
		history.push('/shipping')
	}

	const [paymentMethod, setPaymentMethod] = useState('Credit Card')

	const dispatch = useDispatch()

	const paymentHandler = (e) => {
		e.preventDefault()

		dispatch(savePaymentMethod(paymentMethod))
		history.push('/placeorder')
	}

	return (
		<FormContainer>
			<CheckoutSteps step1 step2 step3 />
			<h1>Payment Method</h1>
			<Form onSubmit={paymentHandler}>
				<Form.Group>
					<Form.Label as="legend">Select Payment Method</Form.Label>

					<Col>
						<Form.Check
							type="radio"
							label="Credit Card"
							id="Square"
							name="paymentMethod"
							value="Square"
							checked
							onChange={(e) => setPaymentMethod(e.target.value)}
						></Form.Check>
					</Col>
				</Form.Group>

				<Button
					type="submit"
					variant="primary"
					style={{ backgroundColor: '#3E4F61', borderColor: '#3E4F61' }}
				>
					Continue
				</Button>
			</Form>
		</FormContainer>
	)
}

export default Payment
