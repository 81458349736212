import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
	Row,
	Col,
	ListGroup,
	Image,
	Form,
	Button,
	Card,
	Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FaTrash } from 'react-icons/fa'

import Message from '../components/Message'
import { addToCart, removeFromCart } from '../actions/cartActions'

const Cart = ({ match, location, history }) => {
	const productID = match.params.id

	const qty = location.search ? Number(location.search.split('=')[1]) : 1

	const dispatch = useDispatch()

	const cart = useSelector((state) => state.cart)
	const { cartItems } = cart

	useEffect(() => {
		if (productID) {
			dispatch(addToCart(productID, qty))
		}
	}, [dispatch, productID, qty])

	const removeFromCartHandler = (id) => {
		dispatch(removeFromCart(id))
	}

	const checkoutHandler = () => history.push('/login?redirect=shipping')

	return (
		<Container className="py-5 px-5">
			<Row>
				<Col md={8}>
					<h1>Shopping Cart</h1>
					{cartItems.length === 0 ? (
						<Message variant="info">
							Your Cart is Empty :( <Link to="/">Go Back</Link>
						</Message>
					) : (
						<ListGroup>
							{cartItems.map((item) => (
								<ListGroup.Item
									key={item.product}
									style={{ backgroundColor: 'white' }}
								>
									<Row>
										<Col md={2}>
											<Image
												src={item.image}
												alt={item.name}
												style={{ height: '100px' }}
											></Image>
										</Col>
										<Col md={3}>
											<Link to={`/products/${item.product}`}>{item.name}</Link>
										</Col>
										<Col md={2}>${item.price}</Col>
										<Col md={2}>
											<Form.Control
												as="select"
												value={item.qty}
												onChange={(e) =>
													dispatch(
														addToCart(item.product, Number(e.target.value))
													)
												}
											>
												{[...Array(item.qtyAvail).keys()].map((x) => (
													<option key={x + 1} value={x + 1}>
														{x + 1}
													</option>
												))}
											</Form.Control>
										</Col>
										<Col md={2}>
											<Button
												type="button"
												style={{
													backgroundColor: '#3E4F61',
													borderColor: '#3E4F61',
												}}
												onClick={() => removeFromCartHandler(item.product)}
											>
												<FaTrash />
											</Button>
										</Col>
									</Row>
								</ListGroup.Item>
							))}
						</ListGroup>
					)}
				</Col>
				<Col md={4}>
					<Card>
						<ListGroup>
							<ListGroup.Item
								style={{
									backgroundColor: 'white',
									color: 'black',
									fontWeight: 'bold',
								}}
							>
								<h2>
									Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)}
									) items
								</h2>
								$
								{cartItems
									.reduce((acc, item) => acc + item.qty * item.price, 0)
									.toFixed(2)}
							</ListGroup.Item>
							<ListGroup.Item style={{ backgroundColor: 'white' }}>
								<Button
									type="button"
									className="btn-block"
									disabled={cartItems.length === 0}
									onClick={checkoutHandler}
									style={{ backgroundColor: '#3E4F61' }}
								>
									Proceed to Checkout
								</Button>
							</ListGroup.Item>
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}

export default Cart
