import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'

const SearchBox = ({history, show, close}) => {
    const [keyword, setKeyword] = useState('')

    const handleClose = () => close(false);

    const searchHandler = (e) => {
        e.preventDefault()
        if(keyword.trim()) {
            history.push(`/search/${keyword}`)
            handleClose()
        } else {
            history.push('/')
        }
    }
    return (
        <>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Find what your're looking for!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={searchHandler} inline>
            <Form.Control type='text' name='q' onChange={(e) => setKeyword(e.target.value)} placeholder='Search...' className='mr-sm-2 ml-sm-5'>

            </Form.Control>
            <Button type='submit'>Search</Button>
        </Form>
        </Modal.Body>
      </Modal>
      </>
    )
}

export default SearchBox
