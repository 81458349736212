import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Footer = () => {
	return (
		<footer style={styles.footerContainer} className="d-flex">
			<Container className="d-flex align-self-center justify-content-center">
				<Row>
					<Col>&copy; {new Date().getFullYear()} Sunni Daze Boutique</Col>
				</Row>
			</Container>
		</footer>
	)
}

const styles = {
	footerContainer: {
		width: '100%',
		height: '85px',
		backgroundColor: '#949494',
		color: '#3E4F61',
		fontWeight: 'bold',
		fontSize: '1.0rem',
	},
}

export default Footer
