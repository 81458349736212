import React from 'react'
import Helmet from 'react-helmet'

const Seo = ({ title, description, keywords, category }) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="keyword" content={keywords} />
			<meta name="category" content={category} />
			<meta
				property="og:image"
				content="https://www.sunnidazeboutique.com/sun.svg"
			/>
			<meta property="og:title" content="Sunni Daze Boutique" />
			<meta property="og:description" content="Handmade and High Quality" />
		</Helmet>
	)
}

Seo.defaultProps = {
	title: 'Sunni Daze Boutique',
	description: 'Handmade and High Quality Boutique Items',
	keywords:
		'cups, pens, keychains, lanyards, charms, boutique, handmade, maine made',
	category: '',
}

export default Seo
