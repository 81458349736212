import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from './Message'
import Loading from './Loading'
import FormContainer from './FormContainer'
import { loginUser } from '../actions/userActions'

const Login = ({ location, history }) => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin

	const redirect = location.search ? location.search.split('=')[1] : '/'

	useEffect(() => {
		if (userInfo) {
			history.push(redirect)
		}
	}, [redirect, history, userInfo])

	const loginHandler = (e) => {
		e.preventDefault()
		dispatch(loginUser(email, password))
	}

	return (
		<FormContainer>
			<h1>Sign In</h1>
			{error && <Message variant="danger">{error}</Message>}
			{loading && <Loading />}
			<Form onSubmit={loginHandler}>
				<Form.Group controlId="email">
					<Form.Label>Email Address</Form.Label>
					<Form.Control
						type="email"
						placeholder="Enter Email Address"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId="password">
					<Form.Label>Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Enter password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Button
					type="submit"
					variant="primary"
					style={{ backgroundColor: '#3E4F61', borderColor: '#3E4F61' }}
				>
					Sign In
				</Button>
			</Form>

			<Row className="py-3">
				<Col>
					<Row>
						<Link to={'/forgot-password'}>Forgot Password?</Link>
					</Row>
					<Row style={{ paddingTop: 5 }}>
						<p style={{ paddingRight: 5 }}>New Customer? </p>
						<Link
							to={redirect ? `/register?redirect=${redirect}` : '/register'}
						>
							Register
						</Link>
					</Row>
				</Col>
			</Row>
		</FormContainer>
	)
}

export default Login
