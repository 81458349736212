import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Message from './Message'
import Loading from './Loading'
import FormContainer from './FormContainer'
import { forgotPasswordRequest } from '../actions/userActions'
import { USER_FORGOT_PASSWORD_RESET } from '../constants/userConstants'

const ForgotPassword = () => {
	const dispatch = useDispatch()

	const [email, setEmail] = useState('')
	const [message, setMessage] = useState('')

	const resetInfo = useSelector((state) => state.resetInfo)
	const { loading, error, response } = resetInfo

	const forgotPasswordHandler = (e) => {
		e.preventDefault()
		dispatch(forgotPasswordRequest(email))
		setMessage('Please check your email for a password reset link!')
		setTimeout(() => {
			setMessage('')
		}, 5000)
	}

	useEffect(() => {
		dispatch({ type: USER_FORGOT_PASSWORD_RESET })
	}, [dispatch, message])

	return (
		<FormContainer>
			<h1>Forgot Password</h1>
			{error ? <Message variant="danger">{error}</Message> : null}
			{response === true || response === false ? (
				<Message variant="success">{message}</Message>
			) : null}
			{loading ? (
				<Loading />
			) : (
				<>
					<Form onSubmit={forgotPasswordHandler}>
						<Form.Group controlId="email">
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								type="email"
								placeholder="Enter Email Address"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Button
							type="submit"
							variant="primary"
							style={{ backgroundColor: '#3E4F61', borderColor: '#3E4F61' }}
						>
							Send Password Reset
						</Button>
					</Form>

					<Row className="py-3">
						<Col>
							New Customer? <Link to={'/register'}>Register</Link>
						</Col>
					</Row>
				</>
			)}
		</FormContainer>
	)
}

export default ForgotPassword
