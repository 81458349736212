import React from 'react'
import { Card } from 'react-bootstrap'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const FeaturedItemCard = (props) => {
	return (
		<>
			<Carousel
				swipeable={true}
				draggable={false}
				showDots={true}
				responsive={responsive}
				infinite={true}
				autoPlay={true}
				dotListClass="dot-style"
			>
				{props.items.map((item, index) => (
					<div key={index}>
						<Card
							className="text-center product-card"
							style={{ margin: '1rem' }}
						>
							<Card.Link href={`/products/${item._id}`}>
								<Card.Img
									variant="top"
									src={
										item.images[0]
											? item.images[0].Location
											: 'https://owd-web-storage.nyc3.digitaloceanspaces.com/placeholder-images-image_large.webp'
									}
									style={{
										width: '10rem',
										maxHeight: '15rem',
										height: '15rem',
									}}
								/>
							</Card.Link>
							<Card.Body>
								<Card.Link href={`/products/${item._id}`}>
									<Card.Title>{item.name}</Card.Title>
								</Card.Link>
								<Card.Text style={styles.card}>${item.price}</Card.Text>
							</Card.Body>
						</Card>
					</div>
				))}
			</Carousel>
		</>
	)
}

const styles = {
	card: {
		color: '#3E4F61',
		paddingBottom: '2rem',
	},
}

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
}

export default FeaturedItemCard
