import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, Container, Table } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from './Message'
import Loading from './Loading'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import { listMyOrders } from '../actions/orderActions'
import Seo from './Seo'

const Profile = ({ history }) => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [formError, setFormError] = useState()

	const dispatch = useDispatch()

	const userDetails = useSelector((state) => state.userDetails)
	const { loading, error, user } = userDetails

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
	const { success } = userUpdateProfile

	const orderListMyOrders = useSelector((state) => state.orderListMyOrders)
	const {
		loading: loadingOrders,
		error: errorOrders,
		orders,
	} = orderListMyOrders

	useEffect(() => {
		if (!userInfo) {
			history.push('/login')
		} else {
			if (!user || !user.name || success) {
				dispatch({ type: USER_UPDATE_PROFILE_RESET })
				dispatch(getUserDetails('profile'))
				dispatch(listMyOrders())
			} else {
				setName(user.name)
				setEmail(user.email)
			}
		}
	}, [dispatch, history, userInfo, user, success])

	const updateHandler = (e) => {
		e.preventDefault()

		if (
			name === '' ||
			email === '' ||
			!password === '' ||
			!confirmPassword === ''
		) {
			setFormError('Must Complete all Fields!')
		} else if (password !== confirmPassword) {
			setFormError('Passwords Do Not Match!')
		} else {
			//Dispatch Update Profile
			dispatch(updateUserProfile({ id: user._id, name, email, password }))
		}
	}

	return (
		<Container style={styles.padding}>
			<Seo title="My Profile | Sunni Daze Boutique" />
			<Row>
				<Col md={3}>
					<h2>My Profile</h2>
					{error && <Message variant="danger">{error}</Message>}
					{formError && <Message variant="danger">{formError}</Message>}
					{success && <Message variant="success">Profile Updated!</Message>}
					{loading && <Loading />}
					<Form onSubmit={updateHandler}>
						<Form.Group controlId="name">
							<Form.Label>Name</Form.Label>
							<Form.Control
								type="name"
								placeholder="Full Name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId="email">
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								type="email"
								placeholder="Enter Email Address"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId="password">
							<Form.Label>Password</Form.Label>
							<Form.Control
								type="password"
								placeholder="Enter password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId="password2">
							<Form.Label>Confirm Password</Form.Label>
							<Form.Control
								type="password"
								placeholder="Confirm password"
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Button
							type="submit"
							variant="primary"
							style={{ backgroundColor: '#3E4F61', borderColor: '#3E4F61' }}
						>
							Update
						</Button>
					</Form>
				</Col>
				<Col md={9} className="profile-orders">
					<h2>My Orders</h2>
					{loadingOrders ? (
						<Loading />
					) : errorOrders ? (
						<Message variant="danger">{errorOrders}</Message>
					) : (
						<Table striped bordered hover responsive className="table-sm">
							<thead>
								<tr>
									<th>ID</th>
									<th>Date</th>
									<th>Total</th>
									<th>Paid</th>
									<th>Shipped</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{orders.map((order) => (
									<tr key={order._id}>
										<td>{order._id}</td>
										<td>{order.createdAt.substring(0, 10)}</td>
										<td>${order.totalPrice.toFixed(2)}</td>
										<td>{order.isPaid ? 'Paid' : 'Not Paid'}</td>
										<td>{order.isDelivered ? 'Shipped' : 'Not Shipped'}</td>
										<td align="center">
											<LinkContainer to={`/order/${order._id}`}>
												<Button
													type="submit"
													variant="primary"
													style={{
														backgroundColor: '#3E4F61',
														borderColor: '#3E4F61',
													}}
												>
													Details
												</Button>
											</LinkContainer>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					)}
				</Col>
			</Row>
		</Container>
	)
}

const styles = {
	padding: {
		paddingTop: '5rem',
		paddingBottom: '15rem',
	},
}

export default Profile
