import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'

const Message = ({ variant, padding, children }) => {
	const [showMessage, setShowMessage] = useState(true)

	return (
		<>
			{padding ? (
				<div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
					{showMessage && (
						<Alert
							variant={variant}
							onClose={() => setShowMessage(false)}
							dismissible
						>
							{children}
						</Alert>
					)}
				</div>
			) : (
				<div style={{ margin: '0' }}>
					{showMessage && (
						<Alert
							variant={variant}
							onClose={() => setShowMessage(false)}
							dismissible
						>
							{children}
						</Alert>
					)}
				</div>
			)}
		</>
	)
}

Message.defaultProps = {
	variant: 'info',
	padding: true,
}

export default Message
