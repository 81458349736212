import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loading = () => {
    return (
        <div style={{paddingTop: '15rem', paddingBottom: '15rem'}}>
        <Spinner animation="border" role="status" style={{
            width: '50px',
            height: '50px',
            margin: 'auto',
            display: 'block'
          }}>
        <span className="sr-only">Loading...</span>
        </Spinner>
        <h2 className='text-center' style={{paddingTop: '2rem'}}>Loading...</h2>
        </div>
    )
}

export default Loading
