import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import {
	Container,
	Card,
	Row,
	Col,
	Table,
	Carousel,
	Button,
	Form,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { listProductDetails } from '../actions/productActions'
import Loading from './Loading'
import Message from './Message'
import Seo from './Seo'

const ProductPage = (props) => {
	const [qty, setQty] = useState(1)

	const dispatch = useDispatch()

	const productDetails = useSelector((state) => state.productDetails)
	const { loading, error, product } = productDetails

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const descPattern = /<P>(.*?)<\/P>/gi
	var cleanDescription = product.description

	if (
		cleanDescription &&
		cleanDescription.includes('<') &&
		cleanDescription.match(descPattern)
	) {
		cleanDescription = cleanDescription.match(descPattern)[0]
		cleanDescription = cleanDescription.replace('<P>', '')
		cleanDescription = cleanDescription.replace('</P>', '')
		cleanDescription = cleanDescription.replace('<p>', '')
		cleanDescription = cleanDescription.replace('</p>', '')
		cleanDescription = cleanDescription.replace('<I>', '')
		cleanDescription = cleanDescription.replace('</I>', '')
		cleanDescription = cleanDescription.replace('<i>', '')
		cleanDescription = cleanDescription.replace('</i>', '')
	} else if (cleanDescription && cleanDescription.includes('<I>')) {
		cleanDescription = cleanDescription.replace('<I>', '')
		cleanDescription = cleanDescription.replace('</I>', '')
		cleanDescription = cleanDescription.replace('<i>', '')
		cleanDescription = cleanDescription.replace('</i>', '')
	}

	useEffect(() => {
		dispatch(listProductDetails(props.match.params.id))
	}, [dispatch, props])

	const addToCartHandler = () => {
		props.history.push(`/cart/${props.match.params.id}?qty=${qty}`)
	}
	return (
		<>
			<Seo title={product.name} description={cleanDescription} />
			{loading ? (
				<Loading />
			) : error ? (
				<Message variant="danger">{error}</Message>
			) : (
				<Container className="d-flex justify-content-center py-5">
					<Card>
						<Row>
							<Col md={4}>
								<Carousel>
									{product.images && product.images.length > 0 ? (
										product.images.map((image, index) => (
											<Carousel.Item key={index}>
												<img
													className="d-block w-100"
													src={image.Location}
													alt={`img${index + 1}`}
												/>
											</Carousel.Item>
										))
									) : (
										<Carousel.Item>
											<img
												className="d-block w-100"
												src="https://owd-web-storage.nyc3.digitaloceanspaces.com/placeholder-images-image_large.webp"
												alt="placeholder"
											/>
										</Carousel.Item>
									)}
								</Carousel>
							</Col>
							<Col className="py-5" md={8}>
								<h2 style={styles.name}>{product.name}</h2>

								<h3 style={styles.price}>${product.price}</h3>

								<p style={styles.desc}>{cleanDescription}</p>

								<Card className="mx-5">
									<h5 className="text-center">Product Details</h5>
									<Table striped bordered hover>
										<tbody>
											<tr>
												<td>SKU</td>
												<td>{product.sku}</td>
											</tr>
											<tr>
												<td>Color</td>
												<td style={{ textTransform: 'uppercase' }}>
													{product.color}
												</td>
											</tr>
											<tr>
												<td>Category</td>
												<td style={{ textTransform: 'capitalize' }}>
													{product.category}
												</td>
											</tr>
											<tr>
												<td>Stock Status:</td>
												{product.qty > 0 ? (
													<td style={{ color: 'green' }}>In Stock</td>
												) : (
													<td style={{ color: 'red' }}>Out of Stock</td>
												)}
											</tr>
										</tbody>
									</Table>
								</Card>

								{userInfo &&
									userInfo.isAdmin &&
									product &&
									product.productTrace && (
										<Card className="mx-5">
											<Table
												striped
												bordered
												hover
												responsive
												className="table-sm"
											>
												<thead>
													<tr>
														<th>Date</th>
														<th>Log Message</th>
													</tr>
												</thead>
												<tbody>
													{product.productTrace.map((logs) => (
														<tr key={logs._id}>
															<td className="align-middle">{logs.createdAt}</td>
															<td className="align-middle">{logs.log}</td>
														</tr>
													))}
												</tbody>
											</Table>
										</Card>
									)}

								{product.qty > 0 && (
									<Container className="px-5">
										<Row>
											<Col>Qty</Col>
											<Col>
												<Form.Control
													as="select"
													value={qty}
													onChange={(e) => setQty(e.target.value)}
												>
													{[...Array(product.qty).keys()].map((x) => (
														<option key={x + 1} value={x + 1}>
															{x + 1}
														</option>
													))}
												</Form.Control>
											</Col>
										</Row>
									</Container>
								)}
								<Button
									className="mx-5"
									style={styles.button}
									type="botton"
									disabled={product.qty === 0}
									onClick={addToCartHandler}
								>
									Add To Cart
								</Button>
								{userInfo && userInfo.isAdmin && (
									<LinkContainer to={`/admin/products/${product._id}/edit`}>
										<Button className="mr-2">Edit Product</Button>
									</LinkContainer>
								)}
							</Col>
						</Row>
					</Card>
				</Container>
			)}
		</>
	)
}

const styles = {
	desc: {
		padding: '.5rem',
	},
	price: {
		color: '#3E4F61',
		padding: '.5rem',
	},
	name: {
		padding: '.5rem',
	},
	button: {
		margin: '.5rem',
		backgroundColor: '#3E4F61',
		borderColor: '#3E4F61',
	},
}

export default ProductPage
