import React from 'react'
import { Card, CardDeck } from 'react-bootstrap'

const AllItemCard = (props) => {
	return (
		<>
			<CardDeck className="card-deck mx-auto justify-content-center">
				{props.items.reverse().map((item, index) => (
					<div key={index} className="pb-2">
						<Card
							className="text-center product-card"
							style={{ width: '15rem' }}
						>
							<Card.Link href={`/products/${item._id}`}>
								<Card.Img
									variant="top"
									src={
										item.images[0]
											? item.images[0].Location
											: 'https://owd-web-storage.nyc3.digitaloceanspaces.com/placeholder-images-image_large.webp'
									}
									alt="item.name"
								/>
							</Card.Link>
							<Card.Body>
								<Card.Link href={`/products/${item._id}`}>
									<Card.Title>{item.name}</Card.Title>
								</Card.Link>
								<Card.Text style={styles.card}>${item.price}</Card.Text>
							</Card.Body>
						</Card>
					</div>
				))}
			</CardDeck>
		</>
	)
}

const styles = {
	card: {
		color: '#3E4F61',
	},
}

export default AllItemCard
