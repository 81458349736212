import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from './Message'
import Loading from './Loading'
import Paginate from './Paginate'
import {
	listProducts,
	deleteProduct,
	createProduct,
} from '../actions/productActions'
import { PRODUCT_CREATE_RESET } from '../constants/productConstants'

const ProductList = ({ match, history }) => {
	const pageNumber = match.params.pageNumber || 1

	const dispatch = useDispatch()

	const productList = useSelector((state) => state.productList)
	const { loading, error, products, page, pages } = productList

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const productDelete = useSelector((state) => state.productDelete)
	const {
		loading: loadingDelete,
		error: errorDelete,
		success: successDelete,
	} = productDelete

	const productCreate = useSelector((state) => state.productCreate)
	const {
		loading: loadingCreate,
		error: errorCreate,
		success: successCreate,
		product: createdProduct,
	} = productCreate

	useEffect(() => {
		dispatch({ type: PRODUCT_CREATE_RESET })
		if (!userInfo.isAdmin) {
			history.push('/login')
		}

		if (successCreate) {
			history.push(`/admin/products/${createdProduct._id}/edit`)
		} else {
			dispatch(listProducts('', pageNumber))
		}
	}, [
		dispatch,
		history,
		userInfo,
		successDelete,
		successCreate,
		createdProduct,
		pageNumber,
	])

	const deleteProductHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			//Delete Product
			dispatch(deleteProduct(id))
		}
	}

	const createProductHandler = () => {
		//Create Product
		dispatch(createProduct())
	}
	return (
		<Container style={styles.container}>
			<Row className="align-items-center">
				<Col className="text-right">
					<Button className="my-3" onClick={createProductHandler}>
						Create Product
					</Button>
				</Col>
			</Row>
			<h1 className="text-center pb-2">All Products</h1>
			{loadingDelete && <Loading />}
			{errorDelete && <Message variant="danger">{errorDelete}</Message>}
			{loadingCreate && <Loading />}
			{errorCreate && <Message variant="danger">{errorCreate}</Message>}
			{loading ? (
				<Loading />
			) : error ? (
				<Message variant="danger">{error}</Message>
			) : (
				<Table striped bordered hover responsive className="table-sm">
					<thead>
						<tr>
							<th>Image</th>
							<th>SKU</th>
							<th>Name</th>
							<th>Price</th>
							<th>Category</th>
							<th>Quantity</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{products.map((product) => (
							<tr key={product._id}>
								<td>
									<a href={`/products/${product._id}`}>
										<center>
											<img
												src={
													product.images[0]
														? product.images[0].Location
														: 'https://owd-web-storage.nyc3.digitaloceanspaces.com/placeholder-images-image_large.webp'
												}
												alt="Product Image1"
												style={{ height: '250px' }}
											/>
										</center>
									</a>
								</td>
								<td className="align-middle">{product.sku}</td>
								<td className="align-middle">{product.name}</td>
								<td className="align-middle">${product.price.toFixed(2)}</td>
								<td className="align-middle">{product.category}</td>
								<td className="align-middle">{product.qty}</td>
								<td align="center" className="align-middle">
									<LinkContainer to={`/admin/products/${product._id}/edit`}>
										<Button className="mr-2">Edit</Button>
									</LinkContainer>

									<Button
										variant="danger"
										onClick={() => {
											deleteProductHandler(product._id)
										}}
									>
										Delete
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			)}
			<Paginate page={page} pages={pages} isAdmin={true} />
		</Container>
	)
}

const styles = {
	container: {
		marginTop: '5rem',
		marginBottom: '15rem',
		paddingTop: '5rem',
		paddingBottom: '5rem',
		backgroundColor: 'white',
	},
}

export default ProductList
