import React from 'react'
import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const CategoryLinks = () => {
	return (
		<div style={{ backgroundColor: '#E6E6E9' }}>
			<h3 className="text-center pb-2 pt-2">Categories</h3>
			<div className="v-divider pb-2"></div>
			<LinkContainer to="/category/pens" className="category-buttons">
				<Button size="sm" className="justify-content-center d-flex">
					Pens
				</Button>
			</LinkContainer>
			<LinkContainer to="/category/cup" className="category-buttons">
				<Button size="sm" className="justify-content-center d-flex">
					Cups
				</Button>
			</LinkContainer>
			<LinkContainer to="/category/wine stoppers" className="category-buttons">
				<Button size="sm" className="justify-content-center d-flex">
					Wine Stoppers
				</Button>
			</LinkContainer>
			<LinkContainer to="/category/keychains" className="category-buttons">
				<Button size="sm" className="justify-content-center d-flex">
					Keychains
				</Button>
			</LinkContainer>
			<LinkContainer to="/category/cup charms" className="category-buttons">
				<Button size="sm" className="justify-content-center d-flex">
					Cup Charms
				</Button>
			</LinkContainer>
			<LinkContainer to="/category/accessories" className="category-buttons">
				<Button size="sm" className="justify-content-center d-flex">
					Accessories
				</Button>
			</LinkContainer>
			<LinkContainer to="/category/zi-collection" className="category-buttons">
				<Button size="sm" className="justify-content-center d-flex">
					Zi Collection
				</Button>
			</LinkContainer>
		</div>
	)
}

export default CategoryLinks
