import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loading from '../components/Loading'
import FormContainer from '../components/FormContainer'
import { listCouponDetails, updateCoupon } from '../actions/couponActions'
import { COUPON_UPDATE_RESET } from '../constants/couponConstants'

const EditCoupon = ({ match, history }) => {
	const couponID = match.params.id

	const [code, setCode] = useState('')
	const [amount, setAmount] = useState(0)
	const [oneTime, setOneTime] = useState(true)
	const [freeShip, setFreeShip] = useState(false)
	const [taxFree, setTaxFree] = useState(false)
	const [expires, setExpires] = useState('')

	const dispatch = useDispatch()

	const couponDetails = useSelector((state) => state.couponDetails)
	const { loading, error, coupon } = couponDetails

	const couponUpdate = useSelector((state) => state.couponUpdate)
	const {
		loading: loadingUpdate,
		success: successUpdate,
		error: errorUpdate,
	} = couponUpdate

	useEffect(() => {
		if (successUpdate) {
			dispatch(listCouponDetails(couponID))
			dispatch({ type: COUPON_UPDATE_RESET })
			history.push('/admin/coupons')
		} else {
			if (!coupon.code || coupon._id !== couponID) {
				dispatch(listCouponDetails(couponID))
			} else {
				setCode(coupon.code)
				setAmount(coupon.amount)
				setOneTime(coupon.oneTime)
				setFreeShip(coupon.freeShip)
				setTaxFree(coupon.taxFree)
				setExpires(coupon.expires)
			}
		}
	}, [dispatch, history, couponID, coupon, successUpdate])

	const updateHandler = (e) => {
		e.preventDefault()
		dispatch(
			updateCoupon({
				_id: couponID,
				code,
				amount,
				oneTime,
				freeShip,
				expires,
				taxFree,
			})
		)
	}

	return (
		<Container style={styles.padding}>
			<Link to="/admin/coupons" className="btn btn-light my-3">
				Go Back
			</Link>
			<FormContainer>
				<h1>Edit Coupon</h1>
				{loadingUpdate && <Loading />}
				{errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
				{loading ? (
					<Loading />
				) : error ? (
					<Message variant="danger">{error}</Message>
				) : (
					<Form onSubmit={updateHandler}>
						<Form.Group controlId="code">
							<Form.Label>Code</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Code"
								value={code}
								onChange={(e) => setCode(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId="amount">
							<Form.Label>Amount?</Form.Label>
							<Form.Control
								type="number"
								placeholder="Enter Amount"
								value={amount}
								onChange={(e) => setAmount(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId="onetime">
							<Form.Check
								type="checkbox"
								label="One Time Use?"
								checked={oneTime}
								onChange={(e) => setOneTime(e.target.checked)}
							></Form.Check>
						</Form.Group>

						<Form.Group controlId="freeship">
							<Form.Check
								type="checkbox"
								label="Free Shipping?"
								checked={freeShip}
								onChange={(e) => setFreeShip(e.target.checked)}
							></Form.Check>
						</Form.Group>

						<Form.Group controlId="taxfree">
							<Form.Check
								type="checkbox"
								label="Tax Free?"
								checked={taxFree}
								onChange={(e) => setTaxFree(e.target.checked)}
							></Form.Check>
						</Form.Group>

						<Form.Group controlId="expires">
							<Form.Label>Expires?</Form.Label>
							<Form.Control
								type="date"
								placeholder="Enter Expire Date"
								value={expires ? expires : '00/00/0000'}
								onChange={(e) => setExpires(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Button type="submit" variant="primary">
							Update
						</Button>
					</Form>
				)}
			</FormContainer>
		</Container>
	)
}

const styles = {
	padding: {
		paddingTop: '5rem',
		paddingBottom: '15rem',
	},
}

export default EditCoupon
