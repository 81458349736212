import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container } from 'react-bootstrap'
import FeaturedItemCard from './FeaturedItemCard'
import { listFeaturedProducts } from '../actions/productActions'

//Bring in components
import About from './About'
import Loading from './Loading'
import Message from './Message'
import Seo from './Seo'

const Home = () => {
	const dispatch = useDispatch()
	const featuredProducts = useSelector((state) => state.featuredProducts)

	const { loading, error, products } = featuredProducts

	useEffect(() => {
		dispatch(listFeaturedProducts())
	}, [dispatch])

	return (
		<>
			<Seo />
			<Container>
				<h2 className="text-center py-5" style={styles.headers}>
					Featured Products
				</h2>
				<div>
					{loading ? (
						<Loading />
					) : error ? (
						<Message variant="danger">{error}</Message>
					) : (
						<FeaturedItemCard items={products} />
					)}
				</div>
				<h2 className="text-center pt-5" style={styles.headers}>
					About Paige
				</h2>
				<About />
			</Container>
		</>
	)
}

const styles = {
	headers: {
		color: '#3E4F61',
	},
}

export default Home
