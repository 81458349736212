import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from './Message'
import Loading from './Loading'
import { getUsers, deleteUser } from '../actions/userActions'

const UserList = ({ history }) => {
	const dispatch = useDispatch()

	const [deletedUser, setDeletedUser] = useState()

	const userList = useSelector((state) => state.userList)
	const { loading, error, users } = userList

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const userDelete = useSelector((state) => state.userDelete)
	const { success: successDelete } = userDelete

	useEffect(() => {
		if (userInfo && userInfo.isAdmin) {
			dispatch(getUsers())
		} else {
			history.push('/login')
		}
	}, [dispatch, history, userInfo, successDelete])

	const deleteUserHandler = (id, name) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteUser(id))
			setDeletedUser(name)
		}
	}
	return (
		<Container style={styles.container}>
			<h1 className="text-center pb-2">All Users</h1>
			{successDelete && (
				<Message variant="success">{`User ${deletedUser} Deleted!`}</Message>
			)}
			{loading ? (
				<Loading />
			) : error ? (
				<Message variant="danger">{error}</Message>
			) : (
				<Table striped bordered hover responsive className="table-sm">
					<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th>Email</th>
							<th>Admin</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{!loading &&
							users &&
							users.map((user) => (
								<tr key={user._id}>
									<td>{user._id}</td>
									<td>{user.name}</td>
									<td>{user.email}</td>
									<td>{user.isAdmin ? 'True' : 'False'}</td>
									<td align="center">
										<LinkContainer to={`/admin/user/${user._id}/edit`}>
											<Button className="mr-2">Edit</Button>
										</LinkContainer>

										<Button
											variant="danger"
											onClick={() => {
												deleteUserHandler(user._id, user.name)
											}}
										>
											Delete
										</Button>
									</td>
								</tr>
							))}
					</tbody>
				</Table>
			)}
		</Container>
	)
}

const styles = {
	container: {
		marginTop: '5rem',
		marginBottom: '15rem',
		paddingTop: '5rem',
		paddingBottom: '5rem',
		backgroundColor: 'white',
	},
}

export default UserList
