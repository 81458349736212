import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
	Container,
	Button,
	Row,
	Col,
	ListGroup,
	Image,
	Card,
	Form,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from './Message'
import CheckoutSteps from '../components/CheckoutSteps'
import { createOrder } from '../actions/orderActions'
import CalculateCart from '../components/CalculateCart'

const PlaceOrder = ({ history }) => {
	const cart = useSelector((state) => state.cart)
	const user = useSelector((state) => state.userLogin)

	const [coupon, setCoupon] = useState('')
	const [couponError, setCouponError] = useState('')
	const [couponSuccess, setCouponSuccess] = useState('')
	const [couponData, setCouponData] = useState()

	const zipCode = cart.shippingAddress.postalCode

	const dispatch = useDispatch()

	//Calculate Price
	cart.itemsPrice = cart.cartItems
		.reduce((acc, item) => acc + item.price * item.qty, 0)
		.toFixed(2)

	cart.taxPrice = Number(0.055 * cart.itemsPrice).toFixed(2)

	cart.totalPrice = Number(cart.itemsPrice) + Number(cart.taxPrice)
	cart.totalPrice = cart.totalPrice.toFixed(2)

	const orderCreate = useSelector((state) => state.orderCreate)
	const { order, success, error } = orderCreate

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (success) {
			history.push(`/order/${order._id}`)
		}
		// eslint-disable-next-line
	}, [history, success])

	const discountHandler = async (e) => {
		e.preventDefault()
		setCouponError('')

		if (!coupon) {
			setCouponError('Invalid Coupon Code!')
		}

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const postData = {
			code: coupon || 'invalid',
			zip: zipCode,
		}

		try {
			const { data } = await axios.post(
				`/api/coupons/validate/`,
				postData,
				config
			)
			setCouponData(data)

			setCouponSuccess(`"${coupon}" Applied!`)
		} catch (error) {
			setCouponError(error.response.data)
		}
	}

	const placeOrderHandler = () => {
		dispatch(
			createOrder({
				orderItems: cart.cartItems,
				shippingAddress: cart.shippingAddress,
				paymentMethod: cart.paymentMethod,
				itemsPrice: cart.itemsPrice,
				shippingPrice: cart.shippingPrice,
				taxPrice: cart.taxPrice,
				totalPrice: cart.totalPrice,
				code: coupon || '',
			})
		)
	}

	return (
		<Container style={styles.padding}>
			<CheckoutSteps step1 step2 step3 step4 />
			<Row>
				<Col md={8}>
					<ListGroup>
						<ListGroup.Item
							style={{ backgroundColor: 'white', color: 'black' }}
						>
							<h2>Shipping</h2>
							<p>
								<strong>Address: </strong>
								{cart.shippingAddress.address}, {cart.shippingAddress.city} ,{' '}
								{cart.shippingAddress.state}, {cart.shippingAddress.postalCode},{' '}
								{cart.shippingAddress.country}
							</p>
						</ListGroup.Item>
						<ListGroup.Item
							style={{ backgroundColor: 'white', color: 'black' }}
						>
							<h2>Payment Method</h2>
							<p>
								<strong>Method: </strong>
								{cart.paymentMethod}
							</p>
						</ListGroup.Item>

						<ListGroup.Item
							style={{ backgroundColor: 'white', color: 'black' }}
						>
							<h2>
								Order Items{' '}
								<Link to="/cart" style={{ fontSize: '1rem' }}>
									(Edit Cart)
								</Link>
							</h2>
							{cart.cartItems.length === 0 ? (
								<Message>Your Cart is Empty :(</Message>
							) : (
								<ListGroup>
									{cart.cartItems.map((item, index) => (
										<ListGroup.Item
											key={index}
											style={{ backgroundColor: 'white', color: 'black' }}
										>
											<Row>
												<Col md={1}>
													<Image
														src={item.image}
														alt={item.name}
														fluid
														rounded
													/>
												</Col>
												<Col>
													<Link to={`/products/${item.product}`}>
														{item.name}
													</Link>
												</Col>
												<Col md={4}>
													{item.qty} x ${item.price} = ${item.qty * item.price}
												</Col>
											</Row>
										</ListGroup.Item>
									))}
								</ListGroup>
							)}
						</ListGroup.Item>
					</ListGroup>
				</Col>
				<Col md={4}>
					<Card className="mb-4">
						<ListGroup>
							<ListGroup.Item
								style={{ backgroundColor: 'white', color: 'black' }}
							>
								<h2>Discount Code</h2>
							</ListGroup.Item>

							<ListGroup.Item
								style={{ backgroundColor: 'white', color: 'black' }}
							>
								<Form onSubmit={discountHandler}>
									<Form.Group controlId="code">
										<Form.Label>Code</Form.Label>
										<Form.Control
											type="text"
											placeholder="Enter Code"
											value={coupon}
											onChange={(e) => setCoupon(e.target.value)}
										></Form.Control>
									</Form.Group>
									{couponError && (
										<Message variant="danger">{couponError}</Message>
									)}
									{couponSuccess && (
										<Message variant="success">{couponSuccess}</Message>
									)}
									<Button
										type="submit"
										variant="primary"
										style={{
											backgroundColor: '#3E4F61',
											borderColor: '#3E4F61',
										}}
									>
										Apply Code
									</Button>
								</Form>
							</ListGroup.Item>
						</ListGroup>
					</Card>
					<Card>
						<ListGroup>
							<CalculateCart
								cart={cart}
								coupon={couponData}
								userToken={user.userInfo.token}
							/>
							{error && <Message variant="danger">{error}</Message>}
							<ListGroup.Item
								style={{ backgroundColor: 'white', color: 'black' }}
							>
								<Button
									type="button"
									className="btn-block"
									disabled={cart.cartItems === 0}
									onClick={placeOrderHandler}
									style={{ backgroundColor: '#3E4F61', borderColor: '#3E4F61' }}
								>
									Proceed to Checkout
								</Button>
							</ListGroup.Item>
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}

const styles = {
	padding: {
		paddingTop: '5rem',
		paddingBottom: '15rem',
	},
}

export default PlaceOrder
