import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { listProducts } from '../actions/productActions'

//Bring in components
import AllItemCard from './AllItemCard'
import Loading from './Loading'
import Message from './Message'
import Paginate from './Paginate'
import PaginateArrows from './PaginateArrows'
import CategoryLinks from './CategoryLinks'
import Seo from './Seo'

const Shop = ({ match }) => {
	const keyword = match.params.keyword
	const category = match.params.category
	var upperCategory

	const uppercaseFirstLetter = (string) => {
		return string
        .split(' ') // Split the string into an array of words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
        .join(' '); // Join the array of words back into a string
	}

	if (category) {
		upperCategory = uppercaseFirstLetter(category)
	}

	const pageNumber = match.params.pageNumber || 1

	const dispatch = useDispatch()
	const productList = useSelector((state) => state.productList)

	const { loading, error, products, page, pages } = productList

	useEffect(() => {
		dispatch(listProducts(keyword, pageNumber, category))
	}, [dispatch, keyword, pageNumber, category])

	return (
		<>
			{!keyword && !category ? (
				<Seo title="Shop With Me! | Sunni Daze Boutique" />
			) : keyword ? (
				<Seo title={'Search For: "' + keyword + '"'} />
			) : (
				<Seo title={'Category: ' + category} />
			)}
			{loading ? (
				<Loading />
			) : error ? (
				<Message variant="danger">{error}</Message>
			) : (
				<Container>
					{!keyword && !category ? (
						<Container>
							<h2 className="text-center py-5" style={styles.headers}>
								Our Products
							</h2>
							<PaginateArrows
								pages={pages}
								page={page}
								keyword={keyword ? keyword : ''}
								category={category ? category : ''}
							/>
						</Container>
					) : keyword ? (
						<h2 className="text-center py-5" style={styles.headers}>
							{'Search Results For: "' + keyword + '"'}
						</h2>
					) : category ? (
						<h2 className="text-center py-5" style={styles.headers}>
							{'Browse All ' + upperCategory}
						</h2>
					) : (
						''
					)}

					<Row>
						{!keyword && !category && (
							<Col
								sm={2}
								className="category-container"
								style={{
									backgroundColor: '#fff',
									width: '100%',
									height: '18rem',
								}}
							>
								<CategoryLinks />
							</Col>
						)}

						<Col>
							<div>
								{keyword && (
									<Row>
										<Col>
											<Link
												to="/shop"
												className="btn btn-dark back-btn"
												style={{
													backgroundColor: '#3E4F61',
													borderColor: '#3E4F61',
													marginBottom: '2rem',
												}}
											>
												Go Back
											</Link>
										</Col>
										<Col>
											<PaginateArrows
												pages={pages}
												page={page}
												keyword={keyword ? keyword : ''}
												category={category ? category : ''}
											/>
										</Col>
									</Row>
								)}

								{category && (
									<Row>
										<Col>
											<Link
												to="/shop"
												className="btn btn-dark back-btn"
												style={{
													backgroundColor: '#3E4F61',
													borderColor: '#3E4F61',
													marginBottom: '2rem',
												}}
											>
												Go Back
											</Link>
										</Col>
										<Col>
											<PaginateArrows
												pages={pages}
												page={page}
												keyword={keyword ? keyword : ''}
												category={category ? category : ''}
											/>
										</Col>
									</Row>
								)}
								<AllItemCard items={products} />
							</div>
						</Col>
					</Row>
					<Paginate
						pages={pages}
						page={page}
						keyword={keyword ? keyword : ''}
						category={category ? category : ''}
					/>
				</Container>
			)}
		</>
	)
}

const styles = {
	headers: {
		color: '#3E4F61',
	},
}

export default Shop
