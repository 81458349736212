import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

//Import Reducers
import {
	productListReducer,
	productDetailsReducer,
	productDeleteReducer,
	productCreateReducer,
	productUpdateReducer,
	productLogUpdateReducer,
	featuredProductListReducer,
} from './reducers/productReducers'
import { cartReducer } from './reducers/cartReducers'
import {
	userLoginReducer,
	userRegisterReducer,
	userDetailsReducer,
	userUpdateProfileReducer,
	userListReducer,
	userDeleteReducer,
	userUpdateReducer,
	userForgotPasswordReducer,
} from './reducers/userReducers'
import {
	orderCreateReducer,
	orderDetailsReducer,
	orderPayReducer,
	orderListMyOrders,
	orderListReducer,
	orderDeleteReducer,
	orderShippedReducer,
} from './reducers/orderReducer'
import {
	couponListReducer,
	couponDeleteReducer,
	couponUpdateReducer,
	couponDetailsReducer,
	couponCreateReducer,
} from './reducers/couponReducer'

const reducer = combineReducers({
	productList: productListReducer,
	productDetails: productDetailsReducer,
	cart: cartReducer,
	userLogin: userLoginReducer,
	userRegister: userRegisterReducer,
	userDetails: userDetailsReducer,
	userUpdateProfile: userUpdateProfileReducer,
	orderCreate: orderCreateReducer,
	orderDetails: orderDetailsReducer,
	orderPay: orderPayReducer,
	orderListMyOrders: orderListMyOrders,
	userList: userListReducer,
	userDelete: userDeleteReducer,
	userUpdate: userUpdateReducer,
	resetInfo: userForgotPasswordReducer,
	productDelete: productDeleteReducer,
	productCreate: productCreateReducer,
	productUpdate: productUpdateReducer,
	orderList: orderListReducer,
	productLog: productLogUpdateReducer,
	couponList: couponListReducer,
	couponDelete: couponDeleteReducer,
	couponUpdate: couponUpdateReducer,
	couponDetails: couponDetailsReducer,
	couponCreate: couponCreateReducer,
	featuredProducts: featuredProductListReducer,
	orderDelete: orderDeleteReducer,
	orderShip: orderShippedReducer,
})

const cartItemsFromStoreage = localStorage.getItem('cartItems')
	? JSON.parse(localStorage.getItem('cartItems'))
	: []
const userInfoFromStoreage = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo'))
	: null
const shippingAddressFromStoreage = localStorage.getItem('shippingAddress')
	? JSON.parse(localStorage.getItem('shippingAddress'))
	: {}

const initialState = {
	cart: {
		cartItems: cartItemsFromStoreage,
		shippingAddress: shippingAddressFromStoreage,
	},
	userLogin: { userInfo: userInfoFromStoreage },
}

const middleware = [thunk]

const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware))
)

export default store
