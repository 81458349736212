import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { slide as Menu } from 'react-burger-menu'
import { LinkContainer } from 'react-router-bootstrap'
import { FaShoppingCart, FaUserAlt, FaSearch } from 'react-icons/fa'
//Bring in components
import Logo from '../images/header-logo'
import { logoutUser } from '../actions/userActions'
import SearchBox from './SearchBox'

const Header = ({ location, history }) => {
	const [show, setShow] = useState(false)
	const [width, setWidth] = useState(window.innerWidth)

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const logoutHandler = () => {
		dispatch(logoutUser())
		history.push('/login')
	}

	const handleClose = (closer) => {
		setShow(closer)
	}

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<>
			{width > 990 ? (
				<>
					<Route
						render={({ history }) => (
							<SearchBox show={show} close={handleClose} history={history} />
						)}
					/>
					<Navbar
						style={{
							display: 'grid',
							gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
							justifyContent: 'center',
						}}
					>
						<div></div>

						<Logo />
						<div
							style={{
								display: 'flex',
								justifyContent: 'end',
								width: '100%',
								paddingRight: '2rem',
							}}
						>
							<Nav.Link
								href="/cart/"
								className="cart-nav"
								style={styles.rightNav}
							>
								<FaShoppingCart
									style={{ fontSize: '1.3rem', marginBottom: '.2rem' }}
								/>
								Cart
							</Nav.Link>

							{userInfo ? (
								<NavDropdown title={userInfo.name} id="username">
									<LinkContainer to="/profile">
										<NavDropdown.Item>Profile</NavDropdown.Item>
									</LinkContainer>
									<NavDropdown.Item onClick={logoutHandler}>
										Logout
									</NavDropdown.Item>
								</NavDropdown>
							) : (
								<Nav.Link href="/login">
									<FaUserAlt
										style={{ fontSize: '1.1rem', marginBottom: '.2rem' }}
									/>
									Login
								</Nav.Link>
							)}

							{userInfo && userInfo.isAdmin && (
								<NavDropdown title="Admin" id="adminmenu">
									<LinkContainer to="/admin/userlist">
										<NavDropdown.Item>Users</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to="/admin/productlist">
										<NavDropdown.Item>Products</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to="/admin/orderlist">
										<NavDropdown.Item>Orders</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to="/admin/coupons">
										<NavDropdown.Item>Coupons</NavDropdown.Item>
									</LinkContainer>
								</NavDropdown>
							)}

							<Nav.Link onClick={() => setShow(true)}>
								<FaSearch
									style={{ fontSize: '1.2rem', marginBottom: '.2rem' }}
								/>
							</Nav.Link>
						</div>
					</Navbar>
					<header style={styles.header_container} className="">
						<Navbar
							collapseOnSelect
							expand="lg"
							className="mobile-navbar-padding"
							bg="transparent"
							variant="dark"
							style={styles.navbar_padding}
						>
							<Navbar.Toggle
								aria-controls="responsive-navbar-nav"
								style={{ justifyContent: 'center' }}
							/>
							<Navbar.Collapse
								id="responsive-navbar-nav"
								style={styles.secondNav}
							>
								<Nav
									className=""
									style={styles.navItem}
									activeKey={location.pathname}
								>
									<Nav.Link href="/">Home</Nav.Link>
									<Nav.Link href="/shop">Shop</Nav.Link>
									<Nav.Link href="/contact">Contact</Nav.Link>
								</Nav>
							</Navbar.Collapse>
						</Navbar>
					</header>
				</>
			) : (
				<>
					<Route
						render={({ history }) => (
							<SearchBox show={show} close={handleClose} history={history} />
						)}
					/>
					<Menu styles={menuStyles}>
						<Nav
							className=""
							style={styles.navItem}
							activeKey={location.pathname}
						>
							<Nav.Link href="/">Home</Nav.Link>
							<Nav.Link href="/shop">Shop</Nav.Link>
							<Nav.Link href="/contact">Contact</Nav.Link>
							<Nav.Link href="/cart/" className="cart-nav">
								<FaShoppingCart
									style={{ fontSize: '1.3rem', marginBottom: '.2rem' }}
								/>
								Cart
							</Nav.Link>

							{userInfo ? (
								<NavDropdown title={userInfo.name} id="username">
									<LinkContainer to="/profile">
										<NavDropdown.Item>Profile</NavDropdown.Item>
									</LinkContainer>
									<NavDropdown.Item onClick={logoutHandler}>
										Logout
									</NavDropdown.Item>
								</NavDropdown>
							) : (
								<Nav.Link href="/login">
									<FaUserAlt
										style={{ fontSize: '1.1rem', marginBottom: '.2rem' }}
									/>
									Login
								</Nav.Link>
							)}

							{userInfo && userInfo.isAdmin && (
								<NavDropdown title="Admin" id="adminmenu">
									<LinkContainer to="/admin/userlist">
										<NavDropdown.Item>Users</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to="/admin/productlist">
										<NavDropdown.Item>Products</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to="/admin/orderlist">
										<NavDropdown.Item>Orders</NavDropdown.Item>
									</LinkContainer>
									<LinkContainer to="/admin/coupons">
										<NavDropdown.Item>Coupons</NavDropdown.Item>
									</LinkContainer>
								</NavDropdown>
							)}

							<Nav.Link onClick={() => setShow(true)}>
								<FaSearch
									style={{ fontSize: '1.2rem', marginBottom: '.2rem' }}
								/>
							</Nav.Link>
						</Nav>
					</Menu>
					<Navbar
						style={{
							display: 'grid',
							gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
							justifyContent: 'center',
						}}
					>
						<div></div>

						<div style={{ paddingTop: '2em' }}>
							<Logo style={{ width: '50px' }} />
						</div>

						<div
							style={{
								display: 'flex',
								justifyContent: 'end',
								width: '100%',
								paddingRight: '2rem',
							}}
						></div>
					</Navbar>
					<header style={styles.header_container} className="">
						<Navbar
							collapseOnSelect
							expand="lg"
							className="mobile-navbar-padding"
							bg="transparent"
							variant="dark"
							style={styles.navbar_padding}
						></Navbar>
					</header>
				</>
			)}
		</>
	)
}

const styles = {
	header_container: {
		width: '100%',
		backgroundColor: 'white',
	},
	row_height: {
		height: '80px',
	},
	headers: {
		color: '#E50569',
		fontFamily: 'Crimson Text',
		fontWeight: 'bold',
	},
	header_logo: {
		height: '150px',
		width: '100%',
		justifyContent: 'center',
		marginBottom: '1rem',
	},
	navItem: {
		fontFamily: 'Lato, sans-serif',
		fontSize: '1rem',
	},
	rightNav: {
		marginLeft: '2rem',
	},
	cart: {
		fontSize: '2rem',
	},
	secondNav: {
		justifyContent: 'center',
		backgroundColor: '#43E4D5',
	},
}

const menuStyles = {
	bmBurgerButton: {
		position: 'fixed',
		width: '30px',
		height: '30px',
		left: '30px',
		top: '30px',
	},
	bmBurgerBars: {
		background: '#373a47',
	},
	bmBurgerBarsHover: {
		background: '#a90000',
	},
	bmCrossButton: {
		height: '24px',
		width: '24px',
	},
	bmCross: {
		background: '#bdc3c7',
	},
	bmMenuWrap: {
		position: 'fixed',
		height: '100%',
	},
	bmMenu: {
		background: '#373a47',
		padding: '2.5em 1.5em 0',
		fontSize: '1.15em',
	},
	bmMorphShape: {
		fill: '#373a47',
	},
	bmItemList: {
		color: '#b8b7ad',
		padding: '0.8em',
	},
	bmItem: {
		display: 'inline-block',
	},
	bmOverlay: {
		background: 'rgba(0, 0, 0, 0.3)',
	},
}

export default Header
