import React from 'react'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const PaginateArrows = ({ pages, page, isAdmin=false, keyword='', category='' }) => {


    return pages > 1 && (
        <Pagination size='sm' className='justify-content-end'>

            <LinkContainer key={page - 1} to={!isAdmin ? keyword ? `/search/${keyword}/page/${page - 1}` : category ? `/category/${category}/page/${page - 1}` : `/shop/page/${page - 1}` : `/admin/productlist/${page - 1}`}>
                    <Pagination.Prev active={page - 1 === page} />
            </LinkContainer>
            <p className='mt-1 ml-1 mr-1 pagination-arrows'>{page} of {[...Array(pages)].length}</p>
            <LinkContainer key={page + 1} to={!isAdmin ? keyword ? `/search/${keyword}/page/${page + 1}` : category ? `/category/${category}/page/${page + 1}` : `/shop/page/${page + 1}` : `/admin/productlist/${page + 1}`}>
                    <Pagination.Next active={page + 1 === page} />
            </LinkContainer>
       
        </Pagination>
    )
}

export default PaginateArrows
