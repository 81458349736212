import axios from 'axios'
import { COUPON_CREATE_FAILED, COUPON_CREATE_REQUEST, COUPON_CREATE_SUCCESS, COUPON_DELETE_FAILED, COUPON_DELETE_REQUEST, COUPON_DELETE_SUCCESS, COUPON_DETAILS_FAILED, COUPON_DETAILS_REQUEST, COUPON_DETAILS_SUCCESS, COUPON_LIST_FAILED, COUPON_LIST_REQUEST, COUPON_LIST_SUCCESS, COUPON_UPDATE_FAILED, COUPON_UPDATE_REQUEST, COUPON_UPDATE_SUCCESS } from '../constants/couponConstants'

export const listCoupons = () => async (dispatch, getState) => {
    try {
        dispatch({ type: COUPON_LIST_REQUEST })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        const { data } = await axios.get(`/api/coupons/`, config)

        dispatch({type: COUPON_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: COUPON_LIST_FAILED,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const deleteCoupon = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: COUPON_DELETE_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

     
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        await axios.delete(`/api/coupons/${id}`, config)

        dispatch({
            type: COUPON_DELETE_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: COUPON_DELETE_FAILED,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const updateCoupon = (coupon) => async (dispatch, getState) => {
    try {
        dispatch({
            type: COUPON_UPDATE_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

     
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        const { data } = await axios.put(`/api/coupons/${coupon._id}`, coupon , config)

        dispatch({
            type: COUPON_UPDATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: COUPON_UPDATE_FAILED,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const listCouponDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: COUPON_DETAILS_REQUEST })

        const { userLogin: { userInfo }} = getState()

     
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        const { data } = await axios.get(`/api/coupons/${id}`, config)

        dispatch({type: COUPON_DETAILS_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: COUPON_DETAILS_FAILED,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const createCoupon = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: COUPON_CREATE_REQUEST,
        })

        const { userLogin: { userInfo }} = getState()

     
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        const { data } = await axios.post(`/api/coupons`, {} , config)

        dispatch({
            type: COUPON_CREATE_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: COUPON_CREATE_FAILED,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}