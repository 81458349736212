import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { SiGmail, SiFacebook, SiInstagram } from 'react-icons/si'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import Seo from './Seo'

const Contact = () => {
	return (
		<Container className="mt-5 mb-5" style={{ minHeight: '' }}>
			<Seo title="Contact Me! | Sunni Daze Boutique" />
			<h2 className="text-center">Contact Us</h2>
			<Row>
				<Col>
					<Card
						style={{
							width: '85%',
							height: 'auto',
							backgroundColor: '#c2c2c2',
							border: 'none',
						}}
						className="d-flex align-items-center m-auto p-5"
					>
						<Row className="mobile-contact">
							<a href="mailto:info@sunnidazeboutique.com">
								<Card
									style={{
										backgroundColor: '#c2c2c2',
										border: 'none',
										color: '#3E4F61',
									}}
								>
									<Col className="text-center">
										<SiGmail style={{ fontSize: '5rem' }} />
									</Col>
									<p className="text-center pt-1 font-weight-bold">Email Me</p>
								</Card>
							</a>

							<Col className="d-flex align-items-center mb-3 contact-dots">
								<BiDotsVerticalRounded style={{ fontSize: '3rem' }} />
							</Col>
							<a href="https://www.facebook.com/sunnidazeboutique">
								<Card
									style={{
										backgroundColor: '#c2c2c2',
										border: 'none',
										color: '#3E4F61',
									}}
								>
									<Col className="text-center">
										<SiFacebook style={{ fontSize: '5rem' }} />
									</Col>
									<p className="text-center pt-1 font-weight-bold">
										Find Me On Facebook
									</p>
								</Card>
							</a>

							<Col className="d-flex align-items-center mb-3 contact-dots">
								<BiDotsVerticalRounded style={{ fontSize: '3rem' }} />
							</Col>
							<a href="https://www.instagram.com/sunnidazeboutique/">
								<Card
									style={{
										backgroundColor: '#c2c2c2',
										border: 'none',
										color: '#3E4F61',
									}}
								>
									<Col className="text-center">
										<SiInstagram style={{ fontSize: '5rem' }} />
									</Col>
									<p className="text-center pt-1 font-weight-bold">
										Follow Me On Instagram
									</p>
								</Card>
							</a>
						</Row>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}

export default Contact
