import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const FormContainer = ({ children }) => {
    return (
        <Container style={styles.padding}>
            <Row className='justify-content-md-center'>
                <Col xs={12} md={6}>
                    {children}
                </Col>
            </Row>
            
        </Container>
    )
}

const styles = {
    padding: {
        paddingTop: '5rem',
        paddingBottom: '15rem'
    }
}

export default FormContainer
