import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Form } from 'react-bootstrap'
import FormContainer from './FormContainer'
import Message from './Message'

const PasswordReset = ({ match, history }) => {
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [message, setMessage] = useState('')
	const [passwordChanged, setPasswordChanged] = useState('')

	const resetToken = match.params.token

	useEffect(() => {
		const verifyToken = () => {
			console.log(resetToken)

			axios
				.get(`/api/users/forgot-password-reset/${resetToken}`)
				.then((success) => {
					console.log(success)
				})
				.catch((error) => {
					console.log(error)
					history.push('/login')
				})
		}

		verifyToken()
	}, [resetToken, history])

	const handleReset = (e) => {
		e.preventDefault()

		if (password !== confirmPassword) {
			setMessage('Passwords do not match!')
		}

		if (password === confirmPassword) {
			axios
				.post(
					`/api/users/forgot-password-reset/${resetToken}`,
					{ password: password },
					{
						headers: {
							'Content-Type': 'application/json',
						},
					}
				)
				.then((success) => {
					console.log(success)
					setMessage('')
					setPasswordChanged(
						'Password Changed.  You will be redirected to the login page in 5 seconds.'
					)
					setTimeout(() => {
						history.push('/login')
					}, 5000)
				})
				.catch((error) => {
					console.log(error)
					setMessage('')
					setMessage('Password Change Failed!')
				})
		}
	}

	return (
		<FormContainer>
			{message && <Message variant="danger">{message}</Message>}
			{passwordChanged && (
				<Message variant="success">{passwordChanged}</Message>
			)}
			<h1>Reset Password</h1>
			<Form onSubmit={handleReset}>
				<Form.Group controlId="password">
					<Form.Label>Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Enter New Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId="confirmPassword">
					<Form.Label>Confirm Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Confirm Password"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Button
					type="submit"
					variant="primary"
					style={{ backgroundColor: '#3E4F61', borderColor: '#3E4F61' }}
				>
					Sign In
				</Button>
			</Form>
		</FormContainer>
	)
}

export default PasswordReset
