import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import Message from './Message'
import Loading from './Loading'
import FormContainer from './FormContainer'
import { registerUser } from '../actions/userActions'

const Register = ({ location, history }) => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [formError, setFormError] = useState()
	const [isReCAPTCHA, setIsReCAPTCHA] = useState(false)

	const dispatch = useDispatch()

	const userRegister = useSelector((state) => state.userRegister)
	const { loading, error, userInfo } = userRegister

	const redirect = location.search ? location.search.split('=')[1] : '/'

	useEffect(() => {
		if (userInfo) {
			history.push(redirect)
		}
	}, [redirect, history, userInfo])

	const registerHandler = (e) => {
		e.preventDefault()

		if (
			name === '' ||
			email === '' ||
			!password === '' ||
			!confirmPassword === ''
		) {
			setFormError('Must Complete all Fields!')
		} else if (password !== confirmPassword) {
			setFormError('Passwords Do Not Match!')
		} else if (!isReCAPTCHA) {
			setFormError('Must Complete ReCAPTCHA!')
		} else {
			dispatch(registerUser(name, email, password))
			console.log('Register')
		}
	}

	return (
		<FormContainer>
			<h1>Create Account</h1>
			{error && <Message variant="danger">{error}</Message>}
			{formError && <Message variant="danger">{formError}</Message>}
			{loading && <Loading />}
			<Form onSubmit={registerHandler}>
				<Form.Group controlId="name">
					<Form.Label>Name</Form.Label>
					<Form.Control
						type="name"
						placeholder="Full Name"
						value={name}
						onChange={(e) => setName(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId="email">
					<Form.Label>Email Address</Form.Label>
					<Form.Control
						type="email"
						placeholder="Enter Email Address"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId="password">
					<Form.Label>Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Enter password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId="password2">
					<Form.Label>Confirm Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Confirm password"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
					></Form.Control>
				</Form.Group>
				<ReCAPTCHA
					sitekey="6Lc1Lz8aAAAAAHD283lcrTunVABuP3vPBCPQKFG0"
					onChange={() => setIsReCAPTCHA(true)}
				/>
				<Button
					className="mt-3"
					type="submit"
					variant="primary"
					style={{ backgroundColor: '#3E4F61', borderColor: '#3E4F61' }}
				>
					Register
				</Button>
			</Form>

			<Row className="py-3">
				<Col>
					Already Registered? <Link to={'/login'}>Login</Link>
				</Col>
			</Row>
		</FormContainer>
	)
}

export default Register
