import React, { useEffect } from 'react'
import axios from 'axios'
import {
	CreditCard,
	PaymentForm,
	ApplePay,
	GooglePay,
} from 'react-square-web-payments-sdk'
import {
	Container,
	Row,
	Col,
	ListGroup,
	Image,
	Card,
	Button,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from './Message'
import Loading from './Loading'
import Seo from './Seo'
import { getOrderDetails, payOrder, shipOrder } from '../actions/orderActions'
import { ORDER_PAY_RESET } from '../constants/orderConstants'

const LoadOrder = ({ match }) => {
	const orderID = match.params.id

	const dispatch = useDispatch()

	const orderDetails = useSelector((state) => state.orderDetails)
	const { order, loading, error } = orderDetails

	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const orderPay = useSelector((state) => state.orderPay)
	const { loading: loadingPay, success: successPay } = orderPay

	const orderShip = useSelector((state) => state.orderShip)
	const {
		loading: loadingShip,
		error: errorShip,
		success: successShip,
	} = orderShip

	if (!loading && order) {
		order.itemsPrice = order.orderItems
			.reduce((acc, item) => acc + item.price * item.qty, 0)
			.toFixed(2)
	}

	useEffect(() => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		if (!order || successPay || order._id !== orderID) {
			dispatch({ type: ORDER_PAY_RESET })
			dispatch(getOrderDetails(orderID))
		} else if (!order.isPaid) {
			axios.post('/api/orders/verify/logs', { order: order }, config)
		}
	}, [orderID, dispatch, order, successPay, userInfo, successShip])

	const successPaymentHandler = (paymentResult) => {
		dispatch(payOrder(orderID, paymentResult))
	}

	const shipOrderHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			//Ship Product
			dispatch(shipOrder(id))
			dispatch(getOrderDetails(orderID))
		}
	}

	return (
		<>
			<Seo title="Checkout - Sunni Daze Boutique" />
			{loadingShip && <Loading />}
			{errorShip && <Message variant="danger">{errorShip}</Message>}
			{loading ? (
				<Loading />
			) : error ? (
				<Message variant="danger">{error}</Message>
			) : (
				<Container style={styles.padding}>
					{!order.isPaid &&
						order.orderVerifyLog.length > 0 &&
						order.orderVerifyLog.map((message, index) => (
							<Message key={index} variant="warning" padding={false}>
								{message.removed} is no longer available, and has been removed.
							</Message>
						))}
					<h2>Order # {orderID}</h2>
					<Row>
						<Col md={8}>
							<ListGroup>
								<ListGroup.Item
									style={{ backgroundColor: 'white', color: 'black' }}
								>
									<h2>Shipping</h2>
									<p>
										<strong>Name: </strong> {order.user.name}
									</p>
									<p>
										<strong>Email: </strong>
										<a href={`mailto:${order.user.email}`}>
											{order.user.email}
										</a>
									</p>
									<p>
										<strong>Address: </strong>
										{order.shippingAddress.address},{' '}
										{order.shippingAddress.city}, {order.shippingAddress.state},{' '}
										{order.shippingAddress.postalCode},{' '}
										{order.shippingAddress.country}
									</p>
									{order.isDelivered ? (
										<Message variant="success">Shipped!</Message>
									) : (
										<Message variant="danger">Not Shipped</Message>
									)}
									{userInfo.isAdmin && !order.isDelivered && (
										<Button
											className="mr-2"
											variant="success"
											onClick={() => {
												shipOrderHandler(order._id)
											}}
										>
											Mark Shipped
										</Button>
									)}
								</ListGroup.Item>
								<ListGroup.Item
									style={{ backgroundColor: 'white', color: 'black' }}
								>
									<h2>Payment Method</h2>
									<p>
										<strong>Method: </strong>
										{order.paymentMethod}
									</p>
									{order.isPaid ? (
										<Message variant="success">Paid on: {order.paidAt}</Message>
									) : (
										<Message variant="danger">Not Paid</Message>
									)}
								</ListGroup.Item>

								<ListGroup.Item
									style={{ backgroundColor: 'white', color: 'black' }}
								>
									<h2>Order Items</h2>
									{order.orderItems.length === 0 ? (
										<Message>Your Order is Empty :(</Message>
									) : (
										<ListGroup>
											{order.orderItems.map((item, index) => (
												<ListGroup.Item
													key={index}
													style={{ backgroundColor: 'white', color: 'black' }}
												>
													<Row>
														<Col md={1}>
															<Image
																src={item.image}
																alt={item.name}
																fluid
																rounded
															/>
														</Col>
														<Col>
															<Link to={`/products/${item.product}`}>
																{item.name}
															</Link>
														</Col>
														<Col md={4}>
															{item.qty} x ${item.price} = $
															{item.qty * item.price}
														</Col>
													</Row>
												</ListGroup.Item>
											))}
										</ListGroup>
									)}
								</ListGroup.Item>
							</ListGroup>
						</Col>
						<Col md={4}>
							<Card>
								<ListGroup>
									<ListGroup.Item
										style={{ backgroundColor: 'white', color: 'black' }}
									>
										<h2>Order Summary</h2>
									</ListGroup.Item>
									<ListGroup.Item
										style={{ backgroundColor: 'white', color: 'black' }}
									>
										<Row>
											<Col>Items</Col>
											<Col>${order.itemsPrice}</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item
										style={{ backgroundColor: 'white', color: 'black' }}
									>
										<Row>
											<Col>Shipping</Col>
											<Col>${order.shippingPrice.toFixed(2)}</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item
										style={{ backgroundColor: 'white', color: 'black' }}
									>
										<Row>
											<Col>Tax</Col>
											<Col>${order.taxPrice.toFixed(2)}</Col>
										</Row>
									</ListGroup.Item>
									{order.couponCode && order.couponCode.amount > 0 && (
										<ListGroup.Item
											style={{ backgroundColor: 'white', color: 'black' }}
										>
											<Row>
												<Col>Discount</Col>
												<Col>${order.couponCode.amount.toFixed(2)}</Col>
											</Row>
										</ListGroup.Item>
									)}

									<ListGroup.Item
										style={{ backgroundColor: 'white', color: 'black' }}
									>
										<Row>
											<Col>Total</Col>
											<Col>${order.totalPrice.toFixed(2)}</Col>
										</Row>
									</ListGroup.Item>
									<br />

									{!order.isPaid && (
										<ListGroup.Item style={{ backgroundColor: 'white' }}>
											{loadingPay && <Loading />}
											<PaymentForm
												applicationId="sandbox-sq0idb-ioJyawGLtLaVWkpC3Lnh3g"
												cardTokenizeResponseReceived={async (token, buyer) => {
													const sqAmount = Math.round(
														(Math.abs(order.totalPrice.toFixed(2)) / 100) *
															10000
													)
													const payToken = token.token
													const config = {
														headers: {
															'Content-Type': 'application/json',
															Authorization: `Bearer ${userInfo.token}`,
														},
													}

													const sqPay = {
														price: sqAmount,
														sourceId: payToken,
													}

													const { data } = await axios.post(
														`/api/orders/processSquare`,
														sqPay,
														config
													)
													const payData = {
														id: data.id,
														status: data.status,
														update_time: data.updatedAt,
														payer: {
															email_address: 'square@sunnidazeboutique.com',
															name: {
																given_name: order.user.name.split(' ')[0],
																surname: order.user.name.split(' ')[1],
															},
														},
													}
													successPaymentHandler(payData)
												}}
												createVerificationDetails={() => ({
													amount: order.totalPrice.toFixed(2),
													/* collected from the buyer */
													billingContact: {
														addressLines: [order.shippingAddress.address],
														familyName: order.user.name.split(' ')[1],
														givenName: order.user.name.split(' ')[0],
														countryCode: 'US',
														city: order.shippingAddress.postalCode,
													},
													currencyCode: 'USD',
													intent: 'CHARGE',
												})}
												createPaymentRequest={() => ({
													countryCode: 'US',
													currencyCode: 'USD',
													total: {
														amount: order.totalPrice.toFixed(2),
														label: 'Total',
													},
												})}
												locationId="L2GW92WZKNNQD"
											>
												<ApplePay />
												<GooglePay />
												<br />
												<CreditCard />
											</PaymentForm>
										</ListGroup.Item>
									)}
								</ListGroup>
							</Card>
						</Col>
					</Row>
				</Container>
			)}
		</>
	)
}

const styles = {
	padding: {
		paddingTop: '5rem',
		paddingBottom: '15rem',
	},
}

export default LoadOrder
