import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { withRouter } from 'react-router'

//Bring in components
import Header from './components/Header.js'
import Footer from './components/Footer.js'
import Home from './components/Home'
import Shop from './components/Shop'
import Contact from './components/Contact'
import ProductPage from './components/ProductPage.js'
import Cart from './components/Cart'
import Login from './components/Login'
import Register from './components/Register'
import Profile from './components/Profile'
import Shipping from './components/Shipping'
import Payment from './components/Payment'
import PlaceOrder from './components/PlaceOrder'
import LoadOrder from './components/LoadOrder'
import UserList from './components/UserList'
import EditUser from './components/EditUser'
import ProductList from './components/ProductList'
import EditProduct from './components/EditProduct'
import OrderList from './components/OrderList'
import CouponList from './components/CouponList'
import EditCoupon from './components/EditCoupon'
import ForgotPassword from './components/ForgotPassword.js'
import PasswordReset from './components/PasswordReset.js'

const HeaderWithRouter = withRouter(Header)

const App = () => {
	return (
		<>
			<Router>
				<HeaderWithRouter />
				<main>
					<Route path="/" exact component={Home} />
					<Route path="/search/:keyword" component={Shop} exact />
					<Route
						path="/search/:keyword/page/:pageNumber"
						component={Shop}
						exact
					/>
					<Route
						path="/category/:category/page/:pageNumber"
						component={Shop}
						exact
					/>
					<Route path="/category/:category" component={Shop} exact />
					<Route path="/shop" exact component={Shop} />
					<Route path="/shop/page/:pageNumber" component={Shop} exact />
					<Route path="/contact" exact component={Contact} />
					<Route path="/login" component={Login} />
					<Route path="/register" component={Register} />
					<Route path="/profile" component={Profile} />
					<Route path="/shipping" component={Shipping} />
					<Route path="/payment" component={Payment} />
					<Route path="/placeorder" component={PlaceOrder} />
					<Route path="/admin/userlist" component={UserList} />
					<Route path="/admin/orderlist" component={OrderList} />
					<Route path="/admin/coupons" component={CouponList} />
					<Route path="/admin/productlist" component={ProductList} exact />
					<Route
						path="/admin/productlist/:pageNumber"
						component={ProductList}
						exact
					/>
					<Route path="/products/:id" component={ProductPage} />
					<Route path="/admin/products/:id/edit" component={EditProduct} />
					<Route path="/admin/coupon/:id/edit" component={EditCoupon} />
					<Route path="/cart/:id?" component={Cart} />
					<Route path="/order/:id" component={LoadOrder} />
					<Route path="/admin/user/:id/edit" component={EditUser} />
					<Route path="/forgot-password" component={ForgotPassword} />
					<Route path="/reset-password/:token" component={PasswordReset} />
				</main>
				<Footer />
			</Router>
		</>
	)
}

export default App
